























































































































































































































































import axios from 'axios';
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TapiConfigPage from './TapiConfigPage.vue';
import Adam6017ConfigPage from './Adam6017ConfigPage.vue';
import MqttConfigPage from './MqttConfigPage.vue';
import Mqtt2ConfigPage from './Mqtt2ConfigPage.vue';
import Adam6066ConfigPage from './Adam6066ConfigPage.vue';
import ThetaConfigPage from './ThetaConfigPage.vue';
import SabioConfig from './SabioConfig.vue';
import DuoConfig2 from './DuoConfig2.vue';
import { TextStrValue } from './types';
import AkConfigPage from './AkConfigPage.vue';
import Adam4000ConfigPage from './Adam4000ConfigPage.vue';
import MoxaE1212ConfigPage from './Moxa1212ConfigPage.vue';
import MoxaE1240ConfigPage from './MoxaE1240ConfigPage.vue';
import VerewaConfig from './VerewaConfig.vue';
import MetOne1020Config from './MetOne1020Config.vue';

interface ProtocolParam {
  protocol: 'tcp' | 'serial' | 'tcpCli' | undefined;
  host?: string;
  comPort?: number;
  speed?: number;
}

interface ProtocolInfo {
  id: string;
  desp: string;
}

interface InstrumentTypeInfo {
  id: string;
  desp: string;
  protocolInfo: Array<ProtocolInfo>;
}

interface Instrument {
  _id: string;
  instType: string;
  protocol: ProtocolParam;
  param: string;
  active: boolean;
  state: string;
  statusType?: Array<any>;
}

export default Vue.extend({
  components: {
    ValidationObserver,
    TapiConfigPage,
    Adam6017ConfigPage,
    MqttConfigPage,
    Mqtt2ConfigPage,
    Adam6066ConfigPage,
    ThetaConfigPage,
    SabioConfig,
    DuoConfig2,
    AkConfigPage,
    Adam4000ConfigPage,
    MoxaE1212ConfigPage,
    MoxaE1240ConfigPage,
    VerewaConfig,
    MetOne1020Config,
  },
  props: {
    isNew: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    inst: {
      type: Object as PropType<Instrument>,
      default: (): Instrument => {
        return {
          _id: '',
          instType: '',
          protocol: {
            protocol: 'tcp',
            host: undefined,
            comPort: undefined,
            speed: undefined,
          },
          param: '{}',
          active: true,
          state: '010',
        };
      },
    },
  },
  data() {
    const emptyForm: Instrument = {
      _id: '',
      instType: '',
      protocol: {
        protocol: undefined,
        host: undefined,
        comPort: undefined,
        speed: 9600,
      },
      param: '{}',
      active: true,
      state: '010',
    };

    let form: Instrument = this.isNew ? emptyForm : this.inst;
    let serialSpeed: Array<TextStrValue> = [
      {
        value: 4800,
        text: '4800',
      },
      {
        value: 9600,
        text: '9600',
      },
      {
        value: 19200,
        text: '19200',
      },
      {
        value: 38400,
        text: '38400',
      },
      {
        value: 38400,
        text: '38400',
      },
      {
        value: 115200,
        text: '115200',
      },
    ];
    return {
      form,
      instrumentTypes: [],
      instTypeMap: new Map<string, InstrumentTypeInfo>(),
      loadingDetailedConfig: false,
      serialSpeed,
    };
  },
  computed: {
    hasDetailConfig(): boolean {
      let list = ['tca08', 'gps'];
      if (list.indexOf(this.form.instType) !== -1) return false;

      return true;
    },
    isCalibratable(): boolean {
      const types = [
        't100',
        't200',
        't201',
        't300',
        't360',
        't400',
        't700',
        'baseline9000',
        'horiba370',
        'picarroG2401',
        'EcoPhysics88P',
      ];
      for (const t of types) {
        if (this.form.instType === t) return true;
      }
      return this.form.instType.startsWith('TcpModbus.');
    },
    isAkInstrument(): boolean {
      return this.form.instType.startsWith('AkProtocol.');
    },
    is8ChAnalogInput(): boolean {
      const compatible = ['adam6017', 'MOXAE1240'];
      for (const t of compatible) {
        if (this.form.instType === t) return true;
      }
      return false;
    },
    analogInputHasAddr(): boolean {
      const compatible = ['MOXAE1240'];
      for (const t of compatible) {
        if (this.form.instType === t) return true;
      }
      return false;
    },
    isMqtt(): boolean {
      return this.form.instType === 'mqtt_client';
    },
    isMqtt2(): boolean {
      return this.form.instType === 'mqtt_client2';
    },
    isAdam6066(): boolean {
      return this.form.instType === 'adam6066';
    },
    isTheta(): boolean {
      return this.form.instType === 'theta';
    },
    instrumentSummary(): string {
      const formNewline = (input: string) => {
        const newline = String.fromCharCode(13, 10);
        return input.replaceAll('\\n', newline);
      };

      let desc = `儀器ID:${this.form._id}\n`;
      desc += `儀器種類:${this.getInstrumentDesc()}\n`;
      desc += `通訊協定:${this.form.protocol.protocol}\n`;
      if (this.form.protocol.protocol === 'tcp')
        desc += `網址:${this.form.protocol.host}\n`;
      else desc += `COM:${this.form.protocol.comPort}\n`;

      if (this.isCalibratable) return (desc += this.calibrationSummary());

      return formNewline(desc);
    },
  },
  async mounted() {
    this.getInstrumentTypes();
  },
  methods: {
    calibrationSummary() {
      let desc = '';
      const param = JSON.parse(this.form.param);
      desc += 'slave ID:' + param.slaveID + '\n';
      if (param.calibrationTime) desc += `校正時間: ${param.calibrationTime}\n`;
      if (param.raiseTime) desc += `校正上升時間: ${param.raiseTime}\n`;
      if (param.holdTime) desc += `校正持續時間: ${param.holdTime} \n`;
      if (param.downTime) desc += `校正下降時間: ${param.downTime} \n`;
      if (param.calibrateZeoSeq)
        desc += '零點校正執行程序:' + param.calibrateZeoSeq + '\n';
      if (param.calibrateSpanSeq)
        desc += '全幅校正執行程序:' + param.calibrateSpanSeq + '\n';
      if (param.calibratorPurgeTime)
        desc += '校正器清空時間:' + param.calibratorPurgeTime + '\n';
      if (param.calibratorPurgeSeq)
        desc += '校正器清空執行程序:' + param.calibratorPurgeSeq + '\n';
      if (param.calibrateZeoDO)
        desc += '零點校正DO:' + param.calibrateZeoDO + '\n';
      if (param.calibrateSpanDO)
        desc += '全幅校正DO:' + param.calibrateSpanDO + '\n';
      if (param.skipInternalVault) desc += '不切換校正電磁閥::不切換';

      return desc;
    },
    async getInstrumentTypes(): Promise<void> {
      const res = await axios.get('/InstrumentTypes');
      this.instrumentTypes = res.data;
      let map = new Map<string, InstrumentTypeInfo>();
      for (const instType of res.data) {
        map.set(instType.id, instType as InstrumentTypeInfo);
      }
      this.instTypeMap = map;
    },
    getInstrumentDesc(): string {
      if (this.instTypeMap.get(this.form.instType)) {
        let info = this.instTypeMap.get(
          this.form.instType,
        ) as InstrumentTypeInfo;
        return info.desp;
      } else return '';
    },
    getProtocolOptions(): Array<ProtocolInfo> {
      if (this.form.instType && this.instTypeMap.get(this.form.instType)) {
        return this.instTypeMap.get(this.form.instType)!.protocolInfo;
      } else return [];
    },
    validateInstType(): Promise<any> {
      return new Promise((resolve, reject) => {
        if (this.$refs.instTypeRules !== null) {
          let rules = this.$refs.instTypeRules as any;
          rules = this.$refs.instTypeRules;
          rules.validate().then((success: boolean) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        }
      });
    },
    validateProtocol(): Promise<any> {
      return new Promise((resolve, reject) => {
        if (this.$refs.protocolRules !== null) {
          let rules = this.$refs.protocolRules as any;
          rules.validate().then((success: boolean) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        }
      });
    },
    validateDetailConfig(): Promise<any> {
      return new Promise((resolve, reject) => {
        if (this.$refs.detailConfigRules !== null) {
          let rules = this.$refs.detailConfigRules as any;
          rules.validate().then((success: boolean) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        }
      });
    },
    onParamChange(v: string) {
      this.form.param = v;
    },
    onStepChange(prevIndex: number, nextIndex: number) {
      if (nextIndex === 2) this.loadingDetailedConfig = true;
    },
    async formSubmitted(): Promise<void> {
      this.form.statusType = undefined;
      const res = await axios.post('/Instrument', this.form);
      const ret = res.data;
      if (ret.ok) {
        this.$emit('submit');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '失敗',
            text: ret.msg,
            icon: 'EditIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
});
